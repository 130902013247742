import React, { lazy, startTransition, Suspense, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// Configuration
import config from '../config';

// Context
import GlobalAuthenticatedContext from 'common/contexts/GlobalAuthenticatedContext';
import GlobalAuthenticatedContextModel from 'common/contexts/GlobalAuthenticatedContextModel';

// Third Party
import { themeOptions } from 'theme';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box, CssBaseline, Toolbar, Backdrop, CircularProgress } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { SnackbarProvider } from 'notistack';
import { LicenseInfo } from '@mui/x-license-pro';

// Util
import RouteWrapper from 'common/components/routing/RouteWrapper';

// Route Components
const ChoiceLoginPage = lazy(() => import('pages/login/ChoiceLogin'));
const OrganizationLoginCallbackPage = lazy(() => import('pages/login/OrganizationLoginCallback'));

const InternalSubscriptionIssueFoundPage = lazy(() => import('pages/issue/InternalSubscriptionIssueFound'));
const NoEnvironmentFoundPage = lazy(() => import('pages/issue/NoEnvironmentFound'));

const UnauthorizedPage = lazy(() => import('pages/Unauthorized'));
const ErrorFoundPage = lazy(() => import('pages/ErrorFound'));
const PageNotFoundPage = lazy(() => import('pages/PageNotFound'));
const AppFoundryPage = lazy(() => import('pages/appfoundry/Appfoundry'));

const ChangelogPage = lazy(() => import('pages/common/Changelog'));
const GuidePage = lazy(() => import('pages/common/Guide'));
const LimitPage = lazy(() => import('pages/common/Limits'));
const JobsPage = lazy(() => import('pages/common/Jobs'));
const UserAudit = lazy(() => import('pages/administration/audit/UserAudit'));
const OrgAudit = lazy(() => import('pages/administration/audit/OrganizationAudit'));
const AccessLog = lazy(() => import('pages/administration/AccessLog'));

const AdminsPage = lazy(() => import('pages/internal/admins/Admins'));
const ModulesPage = lazy(() => import('pages/internal/modules/Modules'));
const SubscriptionsPage = lazy(() => import('pages/internal/subscriptions/Subscriptions'));

// Route Routers
import OrganizationRouter from './organization/OrganizationRouter';
import CXNowRouter from './cxnow/CXNowRouter';

// Don't lazy load initial application entry, header, sidebar.
import Entry from './Entry';
import Sidebar from 'common/components/layout/Sidebar';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_LICENSE);

const ApplicationRouter = ({ }) => {

    const [globalAuthenticated, setGlobalAuthenticated] = useState(GlobalAuthenticatedContextModel);
    const [jobsUpdates, setJobsUpdates] = useState([]);

    const LazyLoadedRoutesFallback = () => {
        return (
            <Backdrop sx={{ color: '#ff4f1f', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    };

    const genesysTheme = createTheme(themeOptions);

    return (
        <BrowserRouter>
            <CssBaseline />
            <ThemeProvider theme={genesysTheme}>
                <GlobalAuthenticatedContext.Provider value={{ globalAuthenticated, setGlobalAuthenticated }}>
                    <SnackbarProvider>
                        <Box sx={{ display: 'flex', width: 'calc(100vw)', height: 'calc(100vh)', overflow: 'hidden' }}>
                            <Sidebar jobsUpdates={jobsUpdates} setJobsUpdates={setJobsUpdates} />
                            <Box component="main" sx={{ flexGrow: 1, p: 1, width: 'calc(100vw)', height: 'calc(100vh)', overflow: 'hidden' }}>
                                {/* <Toolbar variant='dense' /> */}
                                <Grid2 container spacing={2}>
                                    <Grid2 xs={12}>
                                        <Suspense fallback={<LazyLoadedRoutesFallback />}>
                                            <Routes>
                                                <Route path="/" element={<RouteWrapper />}>
                                                    {globalAuthenticated && globalAuthenticated.contextLoaded && (globalAuthenticated.internalSubscription || globalAuthenticated.appFoundry || globalAuthenticated.bypass) && (
                                                        <React.Fragment>
                                                            <Route index element={<Entry />} />
                                                            <Route path="cxnow/*" element={<CXNowRouter jobsUpdates={jobsUpdates} setJobsUpdates={setJobsUpdates} />} />
                                                            <Route path="organization/*" element={<OrganizationRouter />} />
                                                            <Route path="jobs" element={<JobsPage jobsUpdates={jobsUpdates} setJobsUpdates={setJobsUpdates} />} />
                                                            <Route path="changelog" element={<ChangelogPage />} />
                                                            <Route path="limits" element={<LimitPage />} />
                                                            <Route path="guide" element={<GuidePage />} />
                                                            <Route path="admin/audit/user" element={<UserAudit />} />
                                                            <Route path="admin/audit/org" element={<OrgAudit />} />
                                                            <Route path="admin/accesslog" element={<AccessLog />} />
                                                        </React.Fragment>
                                                    )}

                                                    {globalAuthenticated && globalAuthenticated.contextLoaded && globalAuthenticated.isAdmin && (
                                                        <React.Fragment>
                                                            <Route path="internal/admins" element={<AdminsPage />} />
                                                            <Route path="internal/modules" element={<ModulesPage />} />
                                                            <Route path="internal/subscriptions" element={<SubscriptionsPage />} />
                                                        </React.Fragment>
                                                    )}

                                                    <Route path='appfoundry' element={<AppFoundryPage />} />

                                                    <Route path='login' element={<ChoiceLoginPage />} />
                                                    <Route path='/oauth2/source/callback' element={<OrganizationLoginCallbackPage />} />
                                                    
                                                    <Route path="unauthorized" element={<UnauthorizedPage />} />
                                                    <Route path='issue/internal-subscription' element={<InternalSubscriptionIssueFoundPage />} />
                                                    <Route path='issue/no-environment-found' element={<NoEnvironmentFoundPage />} />
                                                    <Route path="error" element={<ErrorFoundPage />} />

                                                    <Route path="*" element={<PageNotFoundPage />} />
                                                </Route>
                                            </Routes>
                                        </Suspense>
                                    </Grid2>
                                </Grid2>
                            </Box>
                        </Box>
                    </SnackbarProvider>
                </GlobalAuthenticatedContext.Provider>
            </ThemeProvider>
        </BrowserRouter>
    );
};

export default ApplicationRouter;